<template>
  <form
    :id="activeForm._id"
    class="mainFormContainer"
    novalidate
    @submit="submitForm($event)"
  >
    <div id="formInfoContainer" class="formsTitle">
      <h2>{{ activeForm.title }}</h2>
      <div class="formDescription">
        <p>{{ activeForm.description }}</p>
      </div>
    </div>
    <div v-if="!loadingFields" id="mainFieldsContainer" class="fieldsContainer">
      <div class="columns is-multiline">
        <div
          v-for="field in activeForm.fields"
          :key="'field-' + field.id"
          class="column"
          :class="{'is-12': field.keyName === 'FormField', 'is-3': field.keyName !== 'FormField'}"
        >
          <component
            :ref="'field-' + field.id"
            :is="field.keyName"
            :field="field"
            :subrecords="field.type === 'form' ? subrecords : null"
            @fieldValueHasChanged="onFieldValueChange"
          />
        </div>
      </div>
    </div>
    <div class="columns buttons">
      <div class="column">
        <router-link
          v-if="isFirstTab"
          :to="`/forms/${$route.params.id}/records`"
          :disabled="inProgress"
          class="button is-fullwidth"
          >Cancel</router-link
        >
        <button
          v-else
          class="button is-primary is-fullwidth"
          @click.prevent="$emit('goToTab', currentTab.idx - 1)"
        >
          Return
        </button>
      </div>
      <div class="column">
        <button
          v-if="!isPreview"
          type="submit"
          class="button is-primary is-fullwidth"
          :class="{ 'is-loading': inProgress }"
          :disabled="inProgress"
        >
          <span v-if="!isLastTab">Save and continue</span>
          <span v-else>Finish</span>
        </button>
        <button
          v-else
          class="button is-primary is-fullwidth"
          @click="$event.preventDefault(); $router.push($route.path);"
        >
          <span>Edit</span>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios';
import { isFieldRequired, isFieldReadOnly } from '@/helpers/general';

/*
 ** Components names declarations must match with the `.keyName` prop in formData prop.
 */
export default {
  name: 'FormRender',
  props: {
    activeForm: {
      type: Object
    },
    mainForm: {
      type: Object
    },
    recordData: {
      type: Object
    },
    formData: {
      type: Object
    },
    tabs: {
      type: Array
    }
  },
  provide: {
    isFieldRequired,
    isFieldReadOnly
  },
  components: {
    SingleLineInputField: () => import('@/components/fields/SingleLineInputField'),
    DropDownInputField: () => import('@/components/fields/DropDownInputField'),
    NumericInputField: () => import('@/components/fields/NumericInputField'),
    TextAreaField: () => import('@/components/fields/TextAreaField'),
    EmailInputField: () => import('@/components/fields/EmailInputField'),
    CheckboxField: () => import('@/components/fields/CheckboxField'),
    RadioButtonField: () => import('@/components/fields/RadioButtonField'),
    TimeInputField: () => import('@/components/fields/TimeInputField'),
    DateInputField: () => import('@/components/fields/DateInputField'),
    UploadFileField: () => import('@/components/fields/UploadFileField'),
    FormField: () => import('@/components/fields/FormField')
  },
  data() {
    return {
      inProgress: false,
      formRecord: this.recordData ?? {},
      currentDependencies: [],
      dependencyMatched: false,
      forms: [],
      loadingFields: true,
    };
  },
  watch: {
    activeForm(newVal) {
      if (newVal) {
        this.currentDependencies = [];
        this.dependencyMatched = false;
        this.fillRecordData();
      }
    },
    isPreview(nValue, oValue) {
      if (!nValue) {
        this.loadingFields = true;
        this.currentDependencies = [];
        this.dependencyMatched = false;
        this.fillRecordData();
        this.$nextTick(() => {
          this.loadingFields = false;
        });
      }
    }
  },
  async beforeMount() {
    const response = await axios.get($formsConfig.core.api.forms)
    this.forms = response.data;
    this.fillRecordData();
    this.loadingFields = false;
  },
  mounted() {
    if (this.$route.params.tab) {
      this.$emit('goToTab', parseInt(this.$route.params.tab, 10));
    }
  },
  computed: {
    isPreview() {
      // Means user is previwing the record and not preforming an edit.
      return this.$route?.query?.preview ?? false;
    },
    currentTab() {
      let currentTab = null;
      this.tabs.forEach(tab => {
        if (tab.id === this.activeForm._id) {
          currentTab = tab;
        }
      });
      return currentTab;
    },
    isFirstTab() {
      return this.currentTab ? this.currentTab.idx === 0 : true;
    },
    isLastTab() {
      return this.currentTab
        ? this.currentTab.idx === this.tabs.length - 1
        : true;
    },
    isMainForm() {
      return this.mainForm._id === this.activeForm._id;
    },
    totalFields () {
      const totalFields = [];
      this.activeForm.fields.forEach(field => {
        if (field.type !== 'form') {
          totalFields.push(field);
        }
      });
      totalFields.push(...this.getSubformFields({ form: this.activeForm }));
      return totalFields;
    },
    subrecords() {
      if (!this.formRecord._id) {
        return []
      }
      if (this.isMainForm) {
        return this.formRecord.subrecords;
      } else {
        // Means we are dealing with a linked form, lets find its subrecords.
        for (const linkedForm of this.formRecord.linkedForms) {
          if (linkedForm._id === this.activeForm._id) {
            return linkedForm.subrecords;
          }
        }
        return [];
      }
    },
    recordId() {
      return this.formRecord?._id ?? null;
    },
  },
  methods: {
    setRecordData({ component, formData, isSubrecord }) {
      if (!component.field) {
        return
      }
      const field = component.field;
      const fieldId = field.id;
      const specialFields = [
        'DropDownInputField',
        'RadioButtonField',
        'CheckboxField'
      ];
      if (!component || !component.isVisible) {
        // The field is not visible, so we can skip the validation.
        return;
      }
      if (
        specialFields.indexOf(component.field.keyName) !== -1 &&
        isFieldRequired(component.field) &&
        component.isVisible === true
      ) {
        if (
          component.fieldValue === 'default' ||
          (component.fieldValue && component.fieldValue.length < 1)
        ) {
          component.valid = false;
          component.errorMessage =
            component.field.keyName === 'CheckboxField'
              ? 'Please, select at least one.'
              : 'Please, select one.';
          return;
        }
      }
      if (component.field.keyName === 'UploadFileField') {
        const file = component.$el.querySelector('input').files[0];
        formData.append('files', file);
        if (!isSubrecord) {
          formData.append(
            'fields',
            JSON.stringify({
              id: fieldId,
              value: file ? component.fieldValue.name : component.fieldValue,
              label: field.label,
              recordId: field.recordId ?? null,
            })
          );
        } else {
          component.field.value = file ? component.fieldValue.name : component.fieldValue;
        }
      } else {
        if (!isSubrecord) {
          formData.append(
            'fields',
            JSON.stringify({
              id: fieldId,
              value: component.fieldValue || component.field.value,
              label: field.label,
              recordId: field.recordId ?? null,
            })
          );
        } else {
          component.field.value = component.fieldValue || component.field.value;
        }
      }
      component.clearValidity();
    },
    /**
     * This function performs the data validation and the
     * data submission if the form is valid.
     * @param {Event} evt The event will be used to cancel the
     * submission in case the form is invalid.
     * @returns boolean
     */
    submitForm(evt) {
      evt.preventDefault();
      const formId = this.activeForm._id;
      const recordId = this.$route.params.recordId || this.recordId;
      const action = recordId ? 'put' : 'post';
      const len = this.totalFields.length;
      const formData = new FormData();
      if (this.isMainForm) {
        formData.append('formId', formId);
      } else {
        formData.append('mainFormId', this.mainForm._id);
        formData.append('form', JSON.stringify(this.activeForm));
        formData.append('linkedForm', true);
      }
      let i = 0;
      const getRecords = ({ component }) => {
        const allRecords = [];
        for (const record of component.records) {
          allRecords.push({
            fieldId: component.field.id,
            ...record
          });
        }
        return allRecords;
      };
      // Cancel the default action for the form as we will send an XHR request.
      // Check if the whole form is valid.
      if (this.$el.checkValidity()) {
        for (i = 0; i < this.activeForm.fields.length; i++) {
          let field = this.activeForm.fields[i];
          let fieldId = field.id;
          let component = this.$refs['field-' + fieldId][0];
          if (component.field.type === 'form') {
            component.$children.forEach(component => {
              this.setRecordData({ component, formData, isSubrecord: true });
            });
            // Go and find sub records.
            const records = getRecords({ component });
            for (const record of records) {
              formData.append(
                'records',
                JSON.stringify(record)
              );
            }
          }
          // Set record data for this field.
          this.setRecordData({ component, formData });
        }
        let uri = $formsConfig.core.api.records.replace('__formId__', formId);
        let options = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };
        if (recordId) {
          uri += recordId;
        }
        this.inProgress = true;
        axios[action](uri, formData, options)
          .then(data => {
            if (this.isLastTab) {
              this.$router.push('/forms/' + this.mainForm._id + '/records/');
            } else {
              this.$notify({
                group: 'alert',
                title: 'SAVED',
                text: 'The changes have been saved',
                type: 'success'
              });
              this.$emit('goToTab', this.currentTab.idx + 1);
              this.formRecord = data.data;
              if (action === 'post') {
                this.$router.push(
                  `/forms/${this.mainForm._id}/${data.data._id}/${this
                    .currentTab.idx + 1}`
                );
                this.$root.$emit('unlockTabs');
              }
            }
          })
          .catch(err => {
            this.$notify({
              group: 'alert',
              title: 'Something went wrong!',
              text:
                (err && err.message) ||
                'We were unable to save your record. Please review your data and try again.',
              type: 'error'
            });
            console.error(err);
          })
          .finally(() => {
            this.inProgress = false;
          });
        return true;
      } else {
        // If the form is invalid, iterate over the fields to mark the ones
        // that need attention.
        for (i = 0; i < this.activeForm.fields.length; i++) {
          let component = this.$refs['field-' + this.activeForm.fields[i].id][0];
          this.validateField({component})
        }
      }
    },
    validateField({component}) {
      if (component.field?.type === 'form') {
        component.$children.forEach(sub => {
          this.validateField({ component: sub });
        });
      } else {
        if (component.isVisible) {
          component.validate();
        }
      }
    },
    getFieldData(field) {
      if (this.isMainForm) {
        return this.formRecord?.fields?.find(f => f.id === field.id);
      } else {
        const linkedForm = this.formRecord?.linkedForms?.find(
          form => form._id === this.activeForm._id
        );
        return linkedForm
          ? linkedForm.fields.find(f => f.id === field.id)
          : null;
      }
    },
    /**
     * This function handles dependencies for the activeForm,
     * it checks every dependency recorded trying to match the new value. If there
     * were no dependencies affected, then it does not emit the update.
     * @param {Object} field The field with the new value.
     * @returns undefined
     */
    onFieldValueChange({ field }) {
      if (!this.currentDependencies.length) {
        return;
      }
      this.dependencyMatched = false;
      this.currentDependencies.forEach(dep => {
        this.matchDependency(field, dep);
      });
      if (this.dependencyMatched) {
        this.$root.$emit('onDependenciesUpdate', { field });
      }
    },
    /**
     * This function handles the record data, adding the right value
     * to each field in the activeForm. Then it finish storing dependencies.
     * @returns undefined
     */
    fillRecordData() {
      // If record exist, fill the input values before render them.
      this.activeForm.fields.forEach(field => {
        if (this.recordId) {
          const recordValue = this.getFieldData(field);
          if (recordValue) {
            // Adding a flag of proof that the field has a record, it is used
            // later to handle dependencies values.
            field.hasRecord = true;
            if (typeof field.selectedOptions !== 'undefined') {
              field.selectedOptions = recordValue.value;
            } else if (typeof field.selectedOption !== 'undefined') {
              field.selectedOption = recordValue.value;
            } else if (typeof field.value !== 'undefined') {
              field.value = recordValue.value;
            }
          }
        }
        // Also, use the loop to store dependendcies to later handle them.
        if (field.dependencies.length) {
          this.currentDependencies.push(field.dependencies);
        }
      });
      // Now that every field has the record, we should start activating
      // dependencies before mounting fields.
      this.currentDependencies.forEach(dep => {
        this.activeForm.fields.forEach(field => {
          this.matchDependency(field, dep);
        });
      });
    },
    /**
     * This function activate or unactivate dependencies for a given field.
     * @param {Object} field The field with the value for the matching.
     * @param {Array} dependencies The list of dependencies to be matched.
     * @returns undefined
     */
    matchDependency(field, dependencies) {
      let fieldValue = null;
      if (field.selectedOption) {
        fieldValue = field.selectedOption;
      } else if (field.selectedOptions) {
        fieldValue = field.selectedOptions;
      } else {
        fieldValue = field.value;
      }
      for (let i = 0; i < dependencies.length; i++) {
        if (field.id === dependencies[i].selectedDepedencyField.id) {
          this.dependencyMatched = true;
          if (fieldValue === dependencies[i].valueToMatch) {
            dependencies[i].isActive = true;
          } else if (
            dependencies[i].valuesToMatch.length > 0 &&
            fieldValue !== undefined
          ) {
            const values = dependencies[i].valuesToMatch;
            if (values.length === fieldValue.length) {
              dependencies[i].isActive = values.every(element =>
                fieldValue.includes(element)
              );
            } else {
              dependencies[i].isActive = false;
            }
          } else {
            dependencies[i].isActive = false;
          }
        }
      }
      return dependencies;
    },
    getSubformFields({ form }) {
      const fields = [];
      form.fields.forEach(field => {
        if (field.type === 'form') {
          const subForm = this.forms.find(form => form._id === field.value)
          if (subForm) {
            subForm.fields.forEach(f => {
              if (f.type === 'form') {
                fields.push(...this.getSubformFields({ form: subForm }))
              } else {
                fields.push(f)
              }
            })
          }
        }
      });
      return fields;
    }
  }
};
</script>

<style lang="scss" scoped>
.mainFormContainer {
  position: relative;
  width: 100%;
  height: calc(100% - 14px - 0.5rem);
  padding: 1rem;
  padding-top: 0;
  margin-top: -1rem;
  overflow-y: auto;
  border: 1px solid #e1e1e1;
  border-top: 0;
  .formsTitle {
    padding: 1.5rem 0;
    margin-bottom: 1rem;
    text-align: center;
    h2 {
      margin: 0;
      font-size: $font-size-normal;
      font-weight: 600;
      color: $sub-subtitle-color;
    }
    .formDescription {
      p {
        margin: 0;
        font-size: $font-size-normal;
        font-weight: normal;
        color: $label-color;
      }
    }
  }
  .fieldsContainer {
    width: 100%;
  }
}
</style>
